<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import appConfig from "@/app.config";

/**
 * Analysis Guide
 */
export default {
  page: {
    title: "Guide für RWA Analyse-Funktionen",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Guide für RWA Analyse-Funktionen",
      items: [
        {
          text: "Dashbaord",
          href: "/"
        },
        {
          text: "Guides",
          href: "/guides"
        },
        {
          text: "Analysis Guide",
          active: true
        }
      ]
    };
  },
  methods: {
    clickOnUploadGuide() {}
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted">
              <!-- R-Score -->
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-1-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">R-Score</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <ul>
                      <li>
                        Der Redem-Score kurz “R-Score”, stellt einen
                        kombinierten Wert aus allen, für eine Frage oder
                        Datenfile, verfügbaren Güte-Scores (z.B.
                        Informations-Score, Vorhersage-Score,...) dar.
                      </li>
                      <li class="mt-2">
                        Alle R-Scores befinden sich in einem Wertebereich von 0
                        bis 100.
                      </li>
                      <li class="mt-2">
                        Durch die Kombination der verschiedenen Güte-Scores
                        werden die Ausprägungen der Antwortqualität der
                        Befragten von jeweils unterschiedlichen Blickwinkeln
                        analysiert.
                      </li>
                      <li class="mt-2">
                        Damit stellt der R-Score, je nach zur Verfügung
                        stehenden Güte-Scores, ein Gesamtbild über die
                        tatsächliche Antwortqualität der Befragten dar.
                      </li>
                      <li class="mt-2">
                        Der R-Score bildet auch die Basis für den
                        Gewichtungsfaktor im Ergebnis. Dabei gilt, je höher der
                        R-Score, desto höher das Gewicht im Ergebnis.
                      </li>
                      <li class="mt-2">
                        <b>Achtung!</b> R-Scores können nur miteinander
                        verglichen werden, wenn jeweils die gleichen Güte-Scores
                        als auch der gleiche Projektionstyp verwendet wurde.
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <!-- Weights -->
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-1-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Gewichtung</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <h6>Redem-Gewichtungsfaktor</h6>
                    <ul>
                      <li>
                        Basierend auf dem R-Score eines Befragten wird ein
                        entsprechender Gewichtungsfaktor berechnet.
                      </li>
                      <li class="mt-2">
                        Die Summe der Gewichte aller Befragten wird immer auf
                        die analysierte Stichprobe skaliert.
                      </li>
                      <li class="mt-2">
                        Dem Redem Gewichtungsfaktor liegt immer der R-Score
                        zugrunde und damit gibt es unterschiedliche
                        Gewichtungsfaktoren auf Datenfile- und auf Frage-Ebene.
                      </li>
                      <li class="mt-2">
                        Die Gewichtungsfaktoren, pro Frage und pro Datenfile
                        können als csv oder xlsx Datei exportiert werden.
                      </li>
                      <li class="mt-2">
                        Ungültige Angaben, werden im Gewichtungsverfahren nicht
                        berücksichtigt und im Ergebnis mit einem Gewicht von 1
                        dargestellt.
                        <ul>
                          <li>Beispiele für ungültige Angaben:</li>
                          <ul>
                            <li>
                              Unvollständige Projektionen (Weniger als 100%,
                              leere Zellen)
                            </li>
                            <li>Keine Projektion für eine Antwort</li>
                          </ul>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-12 mt-4">
                    <h6>
                      Redem Gewichtungsfaktor mit anderem Gewichtungsfaktor
                      kombinieren
                    </h6>
                    <ul>
                      <li>
                        Der Redem-Gewichtungsfaktor kann ganz einfach mit
                        anderen Gewichtungsfaktoren kombiniert werden.
                      </li>
                      <li class="mt-2">
                        Dafür müssen Sie einen anderen Gewichtungsfaktor, wie
                        z.B. eine Demographische-Gewichtung im Upload Prozess
                        hochladen.
                      </li>
                      <li class="mt-2">
                        Danach werden beide Gewichtungsfaktoren via
                        Multiplikation kombiniert und für die Berechnung der
                        Ergebnisse verwendet.
                      </li>
                      <li class="mt-2">
                        Alternativ können Sie die Kombination der
                        Gewichtungsfaktoren außerhalb der Redem Software
                        vornehmen. Dazu können Sie die Gewichte einfach als csv
                        oder xlsx Datei exportieren.
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <!-- Traffic Light System -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-2-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Redem Ampel System</span
                  >
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <ul>
                      <li>
                        Die Redem-Ampel ist eine visuelle Bewertungsmethode,
                        welche die Scores von Datensätze in vier
                        unterschiedliche Kategorien und Farben unterteilt. Die
                        Wertebereiche der Redem-Ampel gelten sowohl für R-Scores
                        als auch für Güte Scores
                      </li>
                      <li class="mt-2">
                        <b>
                          <i class="bx bxs-circle mr-1 text-success"></i> Grün
                          (80-100):</b
                        ><br />
                        Datensatz weist im Sinne der Bewertungsmethode eine hohe
                        bis sehr hohe Qualität auf.
                      </li>
                      <li class="mt-2">
                        <b>
                          <i class="bx bxs-circle mr-1 text-warning"></i> Gelb
                          (50-80):</b
                        ><br />
                        Datensatz weist im Sinne der Bewertungsmethode eine
                        mittlere Qualität auf.
                      </li>
                      <li class="mt-2">
                        <b>
                          <i class="bx bxs-circle mr-1 text-danger"></i> Rot
                          (0-50): </b
                        ><br />
                        Datensatz weist im Sinne der Bewertungsmethode eine
                        geringe bis sehr geringe Qualität auf.
                      </li>
                      <li class="mt-2">
                        <b>
                          <i class="bx bxs-circle mr-1 text-gray"></i>Grau
                          (Ungültig): </b
                        ><br />
                        Datensatz konnte beispielsweise auf Grund einer
                        fehlenden Angabe nicht bewertet werden.
                      </li>
                      <li class="mt-2">
                        Bei Überschneidung zweier Wertebereiche, werden
                        Datensätze, die sich auf den Überschneidungspunkten
                        befinden, immer in die höhere Kategorie eingestuft (z.B.
                        80 = Grün).
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6 mt-4">
                    <!-- <img
                      src="@/assets/images/guide/redem-trafficlight.png"
                      class="img-fluid"
                    /> -->
                  </div>
                </div>
              </b-tab>
              <!-- Quality-Scores -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Güte-Scores</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <ul>
                      <li>
                        Die sogenannten Güte-Scores messen und bewerten die
                        verschiedenen Gütekriterien eines Befragten und werden
                        als “Widgets” in der Web-App dargestellt. Das heißt, für
                        jeden Güte-Score, gibt es einen dahinter liegenden
                        Algorithmus, der die Berechnungen durchführt.
                      </li>
                      <li class="mt-2">
                        Die Wertebereiche der Güte-Scores liegen wie beim
                        R-Score zwischen 0-100 und sind so untereinander
                        vergleichbar.
                      </li>
                      <li class="mt-2">
                        Jeder Güte-Score bewertet 1 Gütekriterium.
                      </li>
                      <li class="mt-2">
                        Die Güte-Scores existieren sowohl auf Datenfile-, Frage-
                        und Datensatz-Ebene.
                      </li>
                      <li class="mt-2">
                        Die Güte-Scores werden laufend erweitert um maximal viel
                        Information aus einem Datensatz zu extrahieren.
                      </li>
                      <li class="mt-2">
                        Derzeit gibt es folgende Güte-Scores:
                        <ul>
                          <li>
                            <b> Informations-Score (IS)</b>
                            <ul>
                              <li>
                                Beim Informations-Score wird das kollektive
                                Informations-Level pro Antwortoption gemessen.
                                Dabei werden Antwortoptionen, die mehr Stimmen
                                erhalten haben, als kollektiv vorhergesagt
                                wurde, mit einem positiven Wert bewertet.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b> Vorhersage-Score (PS)</b>
                            <ul>
                              <li>
                                Der Vorhersage-Score misst, die Abweichung der
                                Projektion/en eines Befragten, im Vergleich zur
                                tatsächlichen Verteilung der Antworten. Damit
                                kann mit dem PS “oberflächliches”
                                Antwortverhalten, auch “Satisficing” genannt,
                                gemessen werden.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6 mt-4">
                    <!-- <img
                      src="@/assets/images/guide/redem-quality-widgets.png"
                      class="img-fluid"
                    /> -->
                  </div>
                  <div class="col-xl-12 mt-5">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-41
                              class="text-dark"
                              href="javascript: void(0);"
                              >Informations-Score (IS)</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-41"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Beim Informations-Score wird das kollektive
                                  Informations-Level pro Antwortoption als auch
                                  die Informations-Differezen in der Stichprobe
                                  gemessen.
                                </li>
                                <li class="mt-2">
                                  Wichtig! Der Informations-Score bewertet nicht
                                  die Antwort jedes einzelnen, sondern die
                                  kollektive Antwort für jede Antwortoption.
                                </li>
                                <li class="mt-2">
                                  Der Informations-Score analysiert wie stark
                                  eine Antwortoption kollektiv unterschätzt
                                  wird. Je mehr eine Antwortoption unterschätzt
                                  wird desto höher der Informations-Score. Wenn
                                  eine Antwortoption kollektiv überschätzt wird,
                                  bekommt diese einen negativen Wert bzw. einen
                                  Informations-Score der geringer als 50 ist.
                                </li>
                                <li class="mt-2">
                                  Bei einem hohen Informations-Level als auch
                                  bei einer geringen Informations-Differenz der
                                  Befragten, werden alle Antwortoptionen, einen
                                  positiven Wert, bzw. einen Informations-Score
                                  der höher ist als 50, aufweisen.
                                </li>
                                <li class="mt-2">
                                  Der Informations Score befördert somit nicht
                                  sichtbare Informationen bzw. Informations
                                  Differenzen ans Tageslicht.
                                </li>
                                <li class="mt-2">
                                  Generell kann man sagen, je mehr
                                  Antwortoptionen einen positiven
                                  Informations-Score haben, desto besser das
                                  gesamte Sample.
                                </li>
                                <li class="mt-2">
                                  Dem Informations-Score liegt die Theorie des
                                  <u> False Consensus Effekt </u> zugrunde.
                                  Dieser besagt, dass eigene Antworten
                                  überschätzt und andere Antworten unterschätzt
                                  werden.
                                </li>
                                <li class="mt-2">
                                  Gemeinsam mit dem Vorhersage-Score (PS) ist
                                  der Informations-Score Teil des sogenannten
                                  <u> Bayesian Truth Serum</u>. Dieses Konzept
                                  entstammt einer über 15 jährigen
                                  Forschungsarbeit am Massachusetts Institute of
                                  Technology (MIT), unter der Leitung von Prof.
                                  Drazen Prelec. Die Ergebnisse wurden auch in
                                  verschiedenen wissenschaftlichen Publikationen
                                  veröffentlicht.
                                </li>
                                <li class="mt-2">
                                  Anwendungen und Einsatzgebiete
                                  <ul>
                                    <li>
                                      Generell Befragungen in denen
                                      Informations-Differenzen auftreten können.
                                    </li>
                                    <li>Kaufabsichten</li>
                                    <li>Mitarbeiterbefragungen</li>
                                    <li>Kundenbefragungen</li>
                                  </ul>
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-42
                              class="text-dark"
                              href="javascript: void(0);"
                              >Vorhersage-Score (VS)</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-42"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Der Vorhersage-Score bewertet die
                                  Prognosefähigkeit jedes einzelnen Befragten.
                                  Je näher die Projektion an der tatsächlichen
                                  Verteilung liegt, desto höher der
                                  Vorhersage-Score eines Befragten.
                                </li>
                                <li class="mt-2">
                                  Bei einer “perfekten” Vorhersage, erhält ein
                                  Befragter einen VS von 100.
                                </li>
                                <li class="mt-2">
                                  Damit misst der Vorhersage-Score, wie
                                  gewissenhaft und glaubwürdig die Antworten der
                                  Befragten sind.
                                </li>
                                <li class="mt-2">
                                  Die Prämisse hinter dem Vorhersage-Score ist
                                  folgende: Befragte mit besserer Sorgfalt im
                                  Antwortverhalten können besser vorhersagen,
                                  wie sich andere verhalten werden.
                                </li>
                                <li class="mt-2">
                                  Gemeinsam mit dem Informations-Score, ist der
                                  Vorhersage-Score Teil des sogenannten Bayesian
                                  Truth Serum. Dieses Konzept entstammt einer
                                  über 15 jährigen Forschungsarbeit am
                                  Massachusetts Institute of Technology (MIT),
                                  unter der Leitung von Prof. Drazen Prelec. Die
                                  Ergebnisse wurden auch in verschiedenen
                                  wissenschaftlichen Publikationen
                                  veröffentlicht.
                                </li>
                                <li class="mt-2">
                                  Anwendungen und Einsatzgebiete
                                  <ul>
                                    <li>
                                      Hauptsächlich Online-Befragungen, aber
                                      auch alle anderen Befragungen wo
                                      oberflächliches Antwortverhalten auftreten
                                      kann.
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Analysis Levels -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Analyse - Ebenen</span>
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-51
                              class="text-dark"
                              href="javascript: void(0);"
                              >Datenfile-Ebene</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-51"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Auf Datenfile-Ebene finden Sie eine
                                  Zusammenfassung und Übersicht der aller
                                  analysierten Datenpunkte des gesamten
                                  Datenfiles.
                                </li>
                                <li>
                                  Die Gewichtungsfaktoren auf Datenfile-Ebene
                                  lassen sich auch auf auch auf Fragen ohnen
                                  projektive Information anwenden.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-52
                              class="text-dark"
                              href="javascript: void(0);"
                              >Frage-Ebene</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-52"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Jede projektive Frage kann auf auch
                                  Frage-Ebene analysiert werden.
                                </li>
                                <li>
                                  Dabei beziehen sich alle angezeigten Daten,
                                  nur auf die einzelne Frage.
                                </li>
                                <li>
                                  Bei der Analyse von Nicht-Projektiven Fragen
                                  können Sie in den Einstellungen auswählen, auf
                                  basis welcher projektiven Fragen die Frage
                                  ausgewertet werden soll.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-53
                              class="text-dark"
                              href="javascript: void(0);"
                              >Datensatz Ebene</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-53"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  In der letzten Ebene kann auch jeder Datensatz
                                  individuell analysiert werden.
                                </li>
                                <li>
                                  Dabei finden Sie die Bewertung nach den
                                  einzelnen Güte-Scores, als auch andere Daten,
                                  wie die einzelnen Projektionen der Befragten.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Settings -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Einstellungen</span>
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-61
                              class="text-dark"
                              href="javascript: void(0);"
                              >Scoringeinstellungen</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-61"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Bei Bedarf können Sie innerhalb einer
                                  projektiven Frage, folgende Einstellungen
                                  vornehmen:
                                </li>
                                <ul>
                                  <li>
                                    Aktivieren/Deaktivieren von einzelnen
                                    Güte-Scores.
                                  </li>
                                  <li>
                                    Den Gewichtungsfaktor der Datenfile-Ebene,
                                    auf eine Frage anwenden
                                  </li>
                                </ul>
                                <li class="mt-3">
                                  Sie können die Änderungen in Ihren
                                  Gewichtungs-Einstellungen entweder Speichern
                                  oder nur Anwenden.
                                </li>
                                <ul>
                                  <li>
                                    Beim Vorgang “Speichern”, werden die
                                    Einstellungen automatisch auch auf
                                    Datenfile-Ebene übernommen.
                                  </li>
                                  <li>
                                    Beim Vorgang “Einstellungen übernehmen”
                                    werden die Änderungen lediglich im Ergebnis
                                    angezeigt, aber nicht gespeichert.
                                  </li>
                                </ul>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-62
                              class="text-dark"
                              href="javascript: void(0);"
                              >Filtereinstellungen</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-62"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Mit dieser Funktion können Sie, wenn
                                  vorhanden, die hochgeladenen Filtervariablen
                                  für eine Frage anwenden.
                                </li>
                                <li>
                                  Je nach Bedarf, können Sie nach einer oder
                                  mehreren Filtervariablen filtern.
                                </li>
                                <li>
                                  Sobald Sie auf den Button “Einstellungen
                                  übernehmen” klicken werden nur noch die Daten
                                  angezeigt, die sich im Filter befinden.
                                </li>
                                <li>
                                  Mit dieser Funktion können Sie ganz einfach,
                                  die Antwort-Güte verschiedener demographischer
                                  Gruppen vergleichen.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-63
                              class="text-dark"
                              href="javascript: void(0);"
                              >Antwortoptionen ausblenden</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-63"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Diese Filtermöglichkeit bezieht sich auf
                                  einzelne Antwortmöglichkeiten.
                                </li>
                                <li>
                                  Sie können auswählen welche Antwortoption/en
                                  ausgeblendet werden sollen. Anschließend
                                  werden alle Datensätze, die diese
                                  Antwortoption gewählt haben, ausgeblendet.
                                </li>
                                <li>
                                  Diese Funktion ist vor allem bei
                                  Antwortoptionen wie “Weiß nicht”, “Keine
                                  Angabe” sinnvoll.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Charts -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Charts</span>
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-71
                              class="text-dark"
                              href="javascript: void(0);"
                              >Antwortverteilung</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-71"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  In diesem Diagramm, werden die Ergebnisse der
                                  Fragen visualisiert.
                                </li>
                                <li>
                                  Dabei werden je nachdem ob ein zusätzlicher
                                  Gewichtungsfaktor vorhanden ist, 2 oder 4
                                  Balken je Antwortoption angezeigt.
                                </li>
                                <ul>
                                  <li>
                                    <b> Ungewichtet: </b> Visualisierung ohne
                                    jegliche Gewichtung.
                                  </li>
                                  <li>
                                    <b> Gewichtet: </b>Hier wird nur der
                                    zusätzliche Gewichtungsfaktor
                                    berücksichtigt. (Wenn zusätzlicher
                                    Gewichtungsfaktor vorhanden)
                                  </li>
                                  <li>
                                    <b>Gewichtungen kombiniert:</b> Es wird der
                                    Redem-Gewichtungsfaktor mit dem zusätzlichen
                                    Gewichtungsfaktor kombiniert. (Wenn
                                    zusätzlicher Gewichtungsfaktor vorhanden)
                                  </li>
                                </ul>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-72
                              class="text-dark"
                              href="javascript: void(0);"
                              >Projektionen</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-72"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Im Projektions-Diagramm werden alle
                                  Antwortoptionen nach den Projektionen und
                                  nicht nach den eigentlichen Antworten
                                  angezeigt.
                                </li>
                                <li>
                                  Diese Ansicht soll einen zusätzlichen Einblick
                                  in das Antwortverhalten der Befragten geben.
                                </li>
                                <li>
                                  Dieses Diagramm ist nur für die
                                  Projektions-Typen, <b> Vollständig </b> und
                                  <b> Durchschnitt </b> verfügbar
                                </li>
                                <li>
                                  Auch hier gibt es verschiedene Balken im
                                  Diagramm:
                                </li>
                                <ul>
                                  <li>
                                    <b>Ungewichtet Projektionen:</b>Jede
                                    Projektion wird im Diagramm gleich stark
                                    gewichtet.
                                  </li>
                                  <li>
                                    <b>Redem-gewichtete Projektionen:</b>Die
                                    Projektionen werden anhand des
                                    Redem-Gewichtungsfaktor gewichtet.
                                  </li>
                                </ul>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-73
                              class="text-dark"
                              href="javascript: void(0);"
                              >Score-Verteilung</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-73"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Das Score-Verteilungs Diagramm
                                  veranschaulicht, in welchen Wertebereichen
                                  sich die jeweiligen Scores befinden.
                                </li>
                                <li>
                                  Das Diagramm visualisiert je nach Ansicht,
                                  R-Scores und Güte-Scores auf Datenfile- als
                                  auch Frage-Ebene.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
