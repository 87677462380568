var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-1-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("R-Score")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Der Redem-Score kurz “R-Score”, stellt einen kombinierten Wert aus allen, für eine Frage oder Datenfile, verfügbaren Güte-Scores (z.B. Informations-Score, Vorhersage-Score,...) dar. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Alle R-Scores befinden sich in einem Wertebereich von 0 bis 100. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Durch die Kombination der verschiedenen Güte-Scores werden die Ausprägungen der Antwortqualität der Befragten von jeweils unterschiedlichen Blickwinkeln analysiert. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Damit stellt der R-Score, je nach zur Verfügung stehenden Güte-Scores, ein Gesamtbild über die tatsächliche Antwortqualität der Befragten dar. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Der R-Score bildet auch die Basis für den Gewichtungsfaktor im Ergebnis. Dabei gilt, je höher der R-Score, desto höher das Gewicht im Ergebnis. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _c("b", [_vm._v("Achtung!")]),
                                _vm._v(
                                  " R-Scores können nur miteinander verglichen werden, wenn jeweils die gleichen Güte-Scores als auch der gleiche Projektionstyp verwendet wurde. "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-1-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Gewichtung")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("h6", [_vm._v("Redem-Gewichtungsfaktor")]),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Basierend auf dem R-Score eines Befragten wird ein entsprechender Gewichtungsfaktor berechnet. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Die Summe der Gewichte aller Befragten wird immer auf die analysierte Stichprobe skaliert. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Dem Redem Gewichtungsfaktor liegt immer der R-Score zugrunde und damit gibt es unterschiedliche Gewichtungsfaktoren auf Datenfile- und auf Frage-Ebene. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Die Gewichtungsfaktoren, pro Frage und pro Datenfile können als csv oder xlsx Datei exportiert werden. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Ungültige Angaben, werden im Gewichtungsverfahren nicht berücksichtigt und im Ergebnis mit einem Gewicht von 1 dargestellt. "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v("Beispiele für ungültige Angaben:"),
                                  ]),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        " Unvollständige Projektionen (Weniger als 100%, leere Zellen) "
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Keine Projektion für eine Antwort"
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12 mt-4" }, [
                            _c("h6", [
                              _vm._v(
                                " Redem Gewichtungsfaktor mit anderem Gewichtungsfaktor kombinieren "
                              ),
                            ]),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Der Redem-Gewichtungsfaktor kann ganz einfach mit anderen Gewichtungsfaktoren kombiniert werden. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Dafür müssen Sie einen anderen Gewichtungsfaktor, wie z.B. eine Demographische-Gewichtung im Upload Prozess hochladen. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Danach werden beide Gewichtungsfaktoren via Multiplikation kombiniert und für die Berechnung der Ergebnisse verwendet. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Alternativ können Sie die Kombination der Gewichtungsfaktoren außerhalb der Redem Software vornehmen. Dazu können Sie die Gewichte einfach als csv oder xlsx Datei exportieren. "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-2-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Redem Ampel System")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Die Redem-Ampel ist eine visuelle Bewertungsmethode, welche die Scores von Datensätze in vier unterschiedliche Kategorien und Farben unterteilt. Die Wertebereiche der Redem-Ampel gelten sowohl für R-Scores als auch für Güte Scores "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _c("b", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle mr-1 text-success",
                                  }),
                                  _vm._v(" Grün (80-100):"),
                                ]),
                                _c("br"),
                                _vm._v(
                                  " Datensatz weist im Sinne der Bewertungsmethode eine hohe bis sehr hohe Qualität auf. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _c("b", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle mr-1 text-warning",
                                  }),
                                  _vm._v(" Gelb (50-80):"),
                                ]),
                                _c("br"),
                                _vm._v(
                                  " Datensatz weist im Sinne der Bewertungsmethode eine mittlere Qualität auf. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _c("b", [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle mr-1 text-danger",
                                  }),
                                  _vm._v(" Rot (0-50): "),
                                ]),
                                _c("br"),
                                _vm._v(
                                  " Datensatz weist im Sinne der Bewertungsmethode eine geringe bis sehr geringe Qualität auf. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _c("b", [
                                  _c("i", {
                                    staticClass: "bx bxs-circle mr-1 text-gray",
                                  }),
                                  _vm._v("Grau (Ungültig): "),
                                ]),
                                _c("br"),
                                _vm._v(
                                  " Datensatz konnte beispielsweise auf Grund einer fehlenden Angabe nicht bewertet werden. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Bei Überschneidung zweier Wertebereiche, werden Datensätze, die sich auf den Überschneidungspunkten befinden, immer in die höhere Kategorie eingestuft (z.B. 80 = Grün). "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-6 mt-4" }),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Güte-Scores")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Die sogenannten Güte-Scores messen und bewerten die verschiedenen Gütekriterien eines Befragten und werden als “Widgets” in der Web-App dargestellt. Das heißt, für jeden Güte-Score, gibt es einen dahinter liegenden Algorithmus, der die Berechnungen durchführt. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Die Wertebereiche der Güte-Scores liegen wie beim R-Score zwischen 0-100 und sind so untereinander vergleichbar. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Jeder Güte-Score bewertet 1 Gütekriterium. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Die Güte-Scores existieren sowohl auf Datenfile-, Frage- und Datensatz-Ebene. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Die Güte-Scores werden laufend erweitert um maximal viel Information aus einem Datensatz zu extrahieren. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Derzeit gibt es folgende Güte-Scores: "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _c("b", [
                                      _vm._v(" Informations-Score (IS)"),
                                    ]),
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          " Beim Informations-Score wird das kollektive Informations-Level pro Antwortoption gemessen. Dabei werden Antwortoptionen, die mehr Stimmen erhalten haben, als kollektiv vorhergesagt wurde, mit einem positiven Wert bewertet. "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("b", [_vm._v(" Vorhersage-Score (PS)")]),
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          " Der Vorhersage-Score misst, die Abweichung der Projektion/en eines Befragten, im Vergleich zur tatsächlichen Verteilung der Antworten. Damit kann mit dem PS “oberflächliches” Antwortverhalten, auch “Satisficing” genannt, gemessen werden. "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-6 mt-4" }),
                          _c("div", { staticClass: "col-xl-12 mt-5" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-41",
                                                  modifiers: {
                                                    "accordion-41": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Informations-Score (IS)")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-41",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Beim Informations-Score wird das kollektive Informations-Level pro Antwortoption als auch die Informations-Differezen in der Stichprobe gemessen. "
                                                  ),
                                                ]),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Wichtig! Der Informations-Score bewertet nicht die Antwort jedes einzelnen, sondern die kollektive Antwort für jede Antwortoption. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Der Informations-Score analysiert wie stark eine Antwortoption kollektiv unterschätzt wird. Je mehr eine Antwortoption unterschätzt wird desto höher der Informations-Score. Wenn eine Antwortoption kollektiv überschätzt wird, bekommt diese einen negativen Wert bzw. einen Informations-Score der geringer als 50 ist. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Bei einem hohen Informations-Level als auch bei einer geringen Informations-Differenz der Befragten, werden alle Antwortoptionen, einen positiven Wert, bzw. einen Informations-Score der höher ist als 50, aufweisen. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Der Informations Score befördert somit nicht sichtbare Informationen bzw. Informations Differenzen ans Tageslicht. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Generell kann man sagen, je mehr Antwortoptionen einen positiven Informations-Score haben, desto besser das gesamte Sample. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Dem Informations-Score liegt die Theorie des "
                                                    ),
                                                    _c("u", [
                                                      _vm._v(
                                                        " False Consensus Effekt "
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " zugrunde. Dieser besagt, dass eigene Antworten überschätzt und andere Antworten unterschätzt werden. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Gemeinsam mit dem Vorhersage-Score (PS) ist der Informations-Score Teil des sogenannten "
                                                    ),
                                                    _c("u", [
                                                      _vm._v(
                                                        " Bayesian Truth Serum"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      ". Dieses Konzept entstammt einer über 15 jährigen Forschungsarbeit am Massachusetts Institute of Technology (MIT), unter der Leitung von Prof. Drazen Prelec. Die Ergebnisse wurden auch in verschiedenen wissenschaftlichen Publikationen veröffentlicht. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Anwendungen und Einsatzgebiete "
                                                    ),
                                                    _c("ul", [
                                                      _c("li", [
                                                        _vm._v(
                                                          " Generell Befragungen in denen Informations-Differenzen auftreten können. "
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v("Kaufabsichten"),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Mitarbeiterbefragungen"
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _vm._v(
                                                          "Kundenbefragungen"
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-42",
                                                  modifiers: {
                                                    "accordion-42": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Vorhersage-Score (VS)")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-42",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Der Vorhersage-Score bewertet die Prognosefähigkeit jedes einzelnen Befragten. Je näher die Projektion an der tatsächlichen Verteilung liegt, desto höher der Vorhersage-Score eines Befragten. "
                                                  ),
                                                ]),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Bei einer “perfekten” Vorhersage, erhält ein Befragter einen VS von 100. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Damit misst der Vorhersage-Score, wie gewissenhaft und glaubwürdig die Antworten der Befragten sind. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Die Prämisse hinter dem Vorhersage-Score ist folgende: Befragte mit besserer Sorgfalt im Antwortverhalten können besser vorhersagen, wie sich andere verhalten werden. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Gemeinsam mit dem Informations-Score, ist der Vorhersage-Score Teil des sogenannten Bayesian Truth Serum. Dieses Konzept entstammt einer über 15 jährigen Forschungsarbeit am Massachusetts Institute of Technology (MIT), unter der Leitung von Prof. Drazen Prelec. Die Ergebnisse wurden auch in verschiedenen wissenschaftlichen Publikationen veröffentlicht. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " Anwendungen und Einsatzgebiete "
                                                    ),
                                                    _c("ul", [
                                                      _c("li", [
                                                        _vm._v(
                                                          " Hauptsächlich Online-Befragungen, aber auch alle anderen Befragungen wo oberflächliches Antwortverhalten auftreten kann. "
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Analyse - Ebenen")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-51",
                                                  modifiers: {
                                                    "accordion-51": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Datenfile-Ebene")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-51",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Auf Datenfile-Ebene finden Sie eine Zusammenfassung und Übersicht der aller analysierten Datenpunkte des gesamten Datenfiles. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Die Gewichtungsfaktoren auf Datenfile-Ebene lassen sich auch auf auch auf Fragen ohnen projektive Information anwenden. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-52",
                                                  modifiers: {
                                                    "accordion-52": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Frage-Ebene")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-52",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Jede projektive Frage kann auf auch Frage-Ebene analysiert werden. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Dabei beziehen sich alle angezeigten Daten, nur auf die einzelne Frage. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Bei der Analyse von Nicht-Projektiven Fragen können Sie in den Einstellungen auswählen, auf basis welcher projektiven Fragen die Frage ausgewertet werden soll. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-53",
                                                  modifiers: {
                                                    "accordion-53": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Datensatz Ebene")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-53",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " In der letzten Ebene kann auch jeder Datensatz individuell analysiert werden. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Dabei finden Sie die Bewertung nach den einzelnen Güte-Scores, als auch andere Daten, wie die einzelnen Projektionen der Befragten. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Einstellungen")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-61",
                                                  modifiers: {
                                                    "accordion-61": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Scoringeinstellungen")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-61",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Bei Bedarf können Sie innerhalb einer projektiven Frage, folgende Einstellungen vornehmen: "
                                                  ),
                                                ]),
                                                _c("ul", [
                                                  _c("li", [
                                                    _vm._v(
                                                      " Aktivieren/Deaktivieren von einzelnen Güte-Scores. "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      " Den Gewichtungsfaktor der Datenfile-Ebene, auf eine Frage anwenden "
                                                    ),
                                                  ]),
                                                ]),
                                                _c(
                                                  "li",
                                                  { staticClass: "mt-3" },
                                                  [
                                                    _vm._v(
                                                      " Sie können die Änderungen in Ihren Gewichtungs-Einstellungen entweder Speichern oder nur Anwenden. "
                                                    ),
                                                  ]
                                                ),
                                                _c("ul", [
                                                  _c("li", [
                                                    _vm._v(
                                                      " Beim Vorgang “Speichern”, werden die Einstellungen automatisch auch auf Datenfile-Ebene übernommen. "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      " Beim Vorgang “Einstellungen übernehmen” werden die Änderungen lediglich im Ergebnis angezeigt, aber nicht gespeichert. "
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-62",
                                                  modifiers: {
                                                    "accordion-62": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Filtereinstellungen")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-62",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Mit dieser Funktion können Sie, wenn vorhanden, die hochgeladenen Filtervariablen für eine Frage anwenden. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Je nach Bedarf, können Sie nach einer oder mehreren Filtervariablen filtern. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Sobald Sie auf den Button “Einstellungen übernehmen” klicken werden nur noch die Daten angezeigt, die sich im Filter befinden. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Mit dieser Funktion können Sie ganz einfach, die Antwort-Güte verschiedener demographischer Gruppen vergleichen. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-63",
                                                  modifiers: {
                                                    "accordion-63": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Antwortoptionen ausblenden"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-63",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Diese Filtermöglichkeit bezieht sich auf einzelne Antwortmöglichkeiten. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Sie können auswählen welche Antwortoption/en ausgeblendet werden sollen. Anschließend werden alle Datensätze, die diese Antwortoption gewählt haben, ausgeblendet. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Diese Funktion ist vor allem bei Antwortoptionen wie “Weiß nicht”, “Keine Angabe” sinnvoll. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Charts")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-71",
                                                  modifiers: {
                                                    "accordion-71": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Antwortverteilung")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-71",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " In diesem Diagramm, werden die Ergebnisse der Fragen visualisiert. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Dabei werden je nachdem ob ein zusätzlicher Gewichtungsfaktor vorhanden ist, 2 oder 4 Balken je Antwortoption angezeigt. "
                                                  ),
                                                ]),
                                                _c("ul", [
                                                  _c("li", [
                                                    _c("b", [
                                                      _vm._v(" Ungewichtet: "),
                                                    ]),
                                                    _vm._v(
                                                      " Visualisierung ohne jegliche Gewichtung. "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _c("b", [
                                                      _vm._v(" Gewichtet: "),
                                                    ]),
                                                    _vm._v(
                                                      "Hier wird nur der zusätzliche Gewichtungsfaktor berücksichtigt. (Wenn zusätzlicher Gewichtungsfaktor vorhanden) "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _c("b", [
                                                      _vm._v(
                                                        "Gewichtungen kombiniert:"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " Es wird der Redem-Gewichtungsfaktor mit dem zusätzlichen Gewichtungsfaktor kombiniert. (Wenn zusätzlicher Gewichtungsfaktor vorhanden) "
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-72",
                                                  modifiers: {
                                                    "accordion-72": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Projektionen")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-72",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Im Projektions-Diagramm werden alle Antwortoptionen nach den Projektionen und nicht nach den eigentlichen Antworten angezeigt. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Diese Ansicht soll einen zusätzlichen Einblick in das Antwortverhalten der Befragten geben. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Dieses Diagramm ist nur für die Projektions-Typen, "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(" Vollständig "),
                                                  ]),
                                                  _vm._v(" und "),
                                                  _c("b", [
                                                    _vm._v(" Durchschnitt "),
                                                  ]),
                                                  _vm._v(" verfügbar "),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Auch hier gibt es verschiedene Balken im Diagramm: "
                                                  ),
                                                ]),
                                                _c("ul", [
                                                  _c("li", [
                                                    _c("b", [
                                                      _vm._v(
                                                        "Ungewichtet Projektionen:"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      "Jede Projektion wird im Diagramm gleich stark gewichtet. "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _c("b", [
                                                      _vm._v(
                                                        "Redem-gewichtete Projektionen:"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      "Die Projektionen werden anhand des Redem-Gewichtungsfaktor gewichtet. "
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-73",
                                                  modifiers: {
                                                    "accordion-73": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Score-Verteilung")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-73",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Das Score-Verteilungs Diagramm veranschaulicht, in welchen Wertebereichen sich die jeweiligen Scores befinden. "
                                                  ),
                                                ]),
                                                _c("li", [
                                                  _vm._v(
                                                    " Das Diagramm visualisiert je nach Ansicht, R-Scores und Güte-Scores auf Datenfile- als auch Frage-Ebene. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }